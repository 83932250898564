// Generated by Framer (1d71865)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["h2ATo5zSK", "CRukOgZr5"];

const serializationHash = "framer-wWMhr"

const variantClassNames = {CRukOgZr5: "framer-v-culzy4", h2ATo5zSK: "framer-v-33i5d9"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"24px dark": "h2ATo5zSK", "24px purple": "CRukOgZr5"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "h2ATo5zSK"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "h2ATo5zSK", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-33i5d9", className, classNames)} data-framer-name={"24px dark"} layoutDependency={layoutDependency} layoutId={"h2ATo5zSK"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({CRukOgZr5: {"data-framer-name": "24px purple"}}, baseVariant, gestureVariant)}><SVG className={"framer-12xyzc3"} data-framer-name={"stroke"} layout={"position"} layoutDependency={layoutDependency} layoutId={"B_4_ur2f1"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 14 8\"><path d=\"M 1 1 L 7 7 L 13 1\" fill=\"transparent\" stroke-width=\"2\" stroke=\"var(--token-92563ce3-1dfb-430c-bd12-a26e5f9f3e1b, rgb(34, 17, 68)) /* {&quot;name&quot;:&quot;shadow planet&quot;} */\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-dasharray=\"\"></path></svg>"} svgContentId={10091810007} withExternalLayout {...addPropertyOverrides({CRukOgZr5: {svg: "<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 14 8\"><path d=\"M 1 1 L 7 7 L 13 1\" fill=\"transparent\" stroke-width=\"2\" stroke=\"var(--token-581724cf-85c7-4c16-9129-0c4972e49d97, rgb(102, 51, 187)) /* {&quot;name&quot;:&quot;purple emperor&quot;} */\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-dasharray=\"\"></path></svg>", svgContentId: 11071966927}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-wWMhr.framer-1ytjju2, .framer-wWMhr .framer-1ytjju2 { display: block; }", ".framer-wWMhr.framer-33i5d9 { height: 24px; overflow: hidden; position: relative; width: 24px; }", ".framer-wWMhr .framer-12xyzc3 { flex: none; height: 8px; left: calc(50.00000000000002% - 14px / 2); position: absolute; top: calc(50.00000000000002% - 8px / 2); width: 14px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"CRukOgZr5":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerf0KUDEgOq: React.ComponentType<Props> = withCSS(Component, css, "framer-wWMhr") as typeof Component;
export default Framerf0KUDEgOq;

Framerf0KUDEgOq.displayName = "Icon / Chevron down";

Framerf0KUDEgOq.defaultProps = {height: 24, width: 24};

addPropertyControls(Framerf0KUDEgOq, {variant: {options: ["h2ATo5zSK", "CRukOgZr5"], optionTitles: ["24px dark", "24px purple"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framerf0KUDEgOq, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})